
.view-container {
    width: 100vw;
    height: 100vh;
    min-width: 1200px;
    background: #F7F8FC;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .header {
        width: 100%;
        height: 60px;
        background: #fff;
        box-shadow: 0 2px 5px 0 rgba(15, 26, 54, 0.05);
        display: flex;
        z-index: 99;

        .login-box {
            width: 100%;
            font-size: 16px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            .title {
                font-size: 18px;
                font-weight: 400;
                color: #333;
            }

            img {
                height: 50px;
                width: 94px;
            }

            .user-info {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .user-name {
                    width: 100px;
                    max-height: 50px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .logout-btn {
                    cursor: pointer;
                    margin-left: 20px;
                    color: #2DC079;
                }
            }
        }
    }

    .main {
        width: 100%;
        height: 1%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url("./../../../assets/images/student/student-exam-bj.png") no-repeat center center;
        background-size: cover;

        .main-content {
            max-height: 724px;
            width: 1300px;
            background: url("./../../../assets/images/student/exam-type-bj.png") no-repeat center center;
            background-size: cover;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            border-radius: 8px;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    padding: 114px 60px 170px;
                }
            }

            .exam-name {
                line-height: 1;
                font-size: 30px;
                font-weight: 500;
                text-align: center;
            }

            .exam-type-box {
                margin-top: 38px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .exam-type {
                    cursor: pointer;
                    margin-top: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: #fff;
                    box-shadow: 0px 0px 13px 0px rgba(56, 56, 56, 0.08);
                    border-radius: 8px;
                    box-sizing: border-box;
                    padding: 30px 20px 20px;
                    width: 280px;
                    transition: 0.3s;

                    span {
                        font-size: 18px;
                        font-weight: 500;
                        margin-top: 20px;
                    }

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            .no-data {
                margin-top: 38px;
                font-size: 24px;
                text-align: center;
                color: #666;
            }
        }
    }
}
